import {
  AppBar,
  Toolbar,
  makeStyles,
  Button,
  IconButton,
  Drawer,
  Grid,
  Box,
  Typography,
  MenuItem,

  Dialog,
  Container, Avatar, Hidden
} from "@material-ui/core";
import NavLink from "../NavLink";
import Select from 'react-select';
import { BsFillPlusCircleFill } from "react-icons/bs";
import { FaDollarSign } from "react-icons/fa";
import MenuIcon from "@material-ui/icons/Menu";
import { BsBellFill } from "react-icons/bs";
import { FiSun } from "react-icons/fi";
import { HiMenuAlt2 } from "react-icons/hi";
import { FaRegMoon } from "react-icons/fa";
import GloballySearchArea from "src/component/GloballySearchArea";
import { useHistory } from "react-router-dom";
import React, { useState, useEffect, useContext } from "react";
import UserProfileComponent from "src/component/UserProfileComponent";
import { Link } from "react-router-dom";
import Logo from "src/component/Logo";
import SettingsContext from "src/context/SettingsContext";
import CloseIcon from '@material-ui/icons/Close';
import NotificationModal from "src/views/pages/Notification/NotificationModal";
const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    width: drawerWidth,

    "& a": {
      textDecoration: "none",
    },
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    margin: "7px 0",
    "& button": {
      borderRadius: "50px",

    },
    "& .notification": {
      color: theme.palette.text.noticetext,
      // fontSize:"30px"
    },
  },
  maindrawer: {
    height: "100%",
    background: "#0c0731",
    width: "260px",
  },
  logoDrawer: {
    paddingLeft: "10px",
    width: "200px",
    marginBottom: "15px",
  },
  drawerContainer: {
    padding: "20px 0px ",
    height: "100%",
    // background: "#1a1a1a",
    width: "260px",
    display: "flex",
    overflow: "hidden",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  drawericon: {
    // color: "#000",
    position: "absolute",
    top: "6px",
    right: "-10px",
    fontSize: "25px",
  },
  logoImg: {
    width: "75px",
    margin: " 14px 15px 11px 0px",
    objectFit: "contain",
    "@media (max-width: 500px)": {
      margin: " 11px 1px 3px 0px",
      width: "52px",
    },
  },
  containerHeight: {
    height: "100%",
  },
  mainHeader: {
    justifyContent: "space-between",
    padding: "0px",
    minHeight: "60px !important",
  },
  menuul: {
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      display: 'block',
    },
    "& li": {
      listStyle: "none", marginLeft: "10px",
      [theme.breakpoints.down("lg")]: {
        // marginTop: '15px',

      }
    },
    "& button": {
      height: "41px",
      [theme.breakpoints.down("sm")]: {
        width: '100%'
      }
    }

  },
  menuSocial: {
    display: "flex",
    justifyContent: "flex-end",
    flexWrap: "wrap",
    "& li": { listStyle: "none", marginLeft: "10px" },
    "& .buttontopBox": {
      boxShadow: theme.palette.background.topbarButtonShadow,
      borderRadius: "50px",
      background: theme.palette.background.selectBox,
      padding: "8px",
      "& svg": {
        fontSize: "15px",
        color: "#73d98b",
      }
    },
    "&  .selectbox3": {
      backgroundColor: "#e0e0e0",
      height: "41px",
      borderRadius: "50px",
      background: theme.palette.background.selectBox,
      boxShadow: theme.palette.background.topbarButtonShadow,
      "& .MuiOutlinedInput-notchedOutline": {
        boxShadow: "inset -10px -10px 20px rgb(255 255 255 / 24%), inset -20px -1px 15px 6px rgb(0 0 0 / 51%) !important",
      },
      "& .selectBox": {
        display: "flex",
        alignItems: "center",
        "& h5": {
          fontSize: "14px",
          marginLeft: "7px",
          fontWeight: "700",
        },
        "& img": {
          width: "22px",
        },
      },
    },

  },
  betBuilderClass: {
    "& button": {
      whiteSpace: "pre",
      [theme.breakpoints.down("md")]: {
        fontSize: "13px",

      }
    }
  },
  modalButtonBox: {
    "& button": {
      padding: "12px 0 !important",
      "&:hover": {
        border: "1px solid #3399AA",
      },
    }
  },
  Topbar: {
    // "& .MuiAppBar-colorPrimary": {
    backgroundColor: "transparent"
    // }
  },
  houseCut: {
    color: theme.palette.text.houseCut,
    textShadow: theme.palette.background.houseshadow,
    fontSize: "13px"
  },
  whiteButton: {
    color: "#fff",
    // height: "39px",
    fontSize: "13px",
    padding: "8px 0px",
    background: "linear-gradient(180deg, #85E2D5 0%, #60C954 100%)",
    boxShadow: "0px 0px 10px #8de6d1, -10px -10px 20px rgb(255 255 255 / 0%), 10px 10px 20px rgb(0 0 0 / 50%)",
    fontWeight: "700",
    // minWidth: "134px",
  },
  dialogPaper: {
    borderRadius: "25px !important"
  },
  ProfilePopperPaper:{
    background:theme.palette.background.profileBackgroundGradient
  }
}));

const options = [
  {
    value: 'chocolate', label: [
      <div className="flexjustify" style={{ height: "40px" }}>
        <img src="images/btc.png" alt="image" style={{ width: "25px" }} />
        <Typography variant="h5" style={{ fontSize: "14px", fontWeight: "700", marginLeft: "10px" }}>BTC</Typography>
      </div>
    ]
  },
  {
    value: 'bnb', label: [
      <div className="flexjustify" style={{ height: "40px" }}>
        <img src="images/bnb.png" alt="image" style={{ width: "25px" }} />
        <Typography variant="h5" style={{ fontSize: "14px", fontWeight: "700", marginLeft: "10px" }}>BNB</Typography>
      </div>
    ]
  },
  {
    value: 'eth', label: [
      <div className="flexjustify" style={{ height: "40px" }}>
        <img src="images/eth.png" alt="image" style={{ width: "25px" }} />
        <Typography variant="h5" style={{ fontSize: "14px", fontWeight: "700", marginLeft: "10px" }}>ETH</Typography>
      </div>
    ]
  },
];

export default function Header({ buttonClick }) {

  const {
    toolbar, Topbar,
    drawerPaper,
    drawerContainer,
    drawericon,
    logoDrawer,
    containerHeight,
    houseCut,
    betBuilderClass,
    mainHeader, menuSocial, modalButtonBox
  } = useStyles();
  const classes = useStyles();
  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const { mobileView, drawerOpen } = state;
  const themeSeeting = useContext(SettingsContext);
  const history = useHistory();
  const [showModal, updateShowModal] = React.useState(false);
  const toggleModal = () => updateShowModal(state => !state);
  const changeTheme = (type) => {
    themeSeeting.saveSettings({
      theme: type,
    });
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [placement, setPlacement] = React.useState();

  const handleClick = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };


  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1220
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());
  }, []);


  const [select1, setSelect1] = useState("BTC");
  const handleChange1 = (event) => {
    setSelect1(event.target.value);
  };
  const [openUserProfile, setOpenUserProfile] = React.useState(false);
  const [openUsenotification, setOpenUsenotification] = React.useState(false);

  const handleClickOpen = () => {
    setOpenUserProfile(true);
  };
  const handleClose = () => {
    setOpenUserProfile(false);
  };
  const handleClickOpen1 = () => {
    setOpenUsenotification(true);
  };
  const handleClose1 = () => {
    setOpenUsenotification(false);
  };

  const [mobileOpen, setMobileOpen] = React.useState(false);
  function handleDrawerToggle() {
    setMobileOpen(!mobileOpen)
  }
  const displayDesktop = () => {
    const drawer = (
      <div>
        {productLogo}
      </div>
    );
    return (
      <Container maxWidth="fixed" className="p-0">
        <Toolbar className={toolbar}>
          <Grid container spacing={1} alignItems='center' justifyContent="center">
            <Grid item md={5}>
              <Grid container spacing={2} alignItems='center' justifyContent="center">
                <Grid item xs={6}>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item md={3}>
                      {productLogo}
                    </Grid>
                    <Grid item xs={9}>
                      <GloballySearchArea type="TopHeaderSearchArea" />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={6}>
                      <Button variant="contained" color="primary" fullWidth onClick={() => history.push("/LeaderBoard")} >
                        LEADERBOARD
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className={betBuilderClass}>
                        <Button variant="contained" color="primary" fullWidth style={{ border: "1px solid #3399AA" }} onClick={() => history.push("/bet-builder")}>
                          BET BUILDER
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={2} >
              <Box style={{ display: 'flex', alignItems: "center", justifyContent: "center" }}>
                <Typography variant="h6" className={houseCut}>HOUSE CUT : 3%</Typography>
              </Box>
            </Grid>
            <Grid item xs={5}>
              <Box className={menuSocial}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item md={3}>
                    <Button variant="contained" fullWidth
                      onClick={() => history.push("/wallet")} className={classes.whiteButton}> DEPOSIT</Button>
                  </Grid>
                  <Grid item md={3}>
                  <Select
                  style={{height:"20px"}}
                    className="ReactSelector"
                    classNamePrefix="react-select"
                    defaultValue={options[0]}
                    onChange={setSelectedOption}
                    options={options}
                    value={options.value}
                  />
             
                  </Grid>
                  <Grid item md={3}>
                    <Button variant="contained" color="primary" fullWidth onClick={() => history.push("/wallet")} className="buttontopBox">
                      <BsFillPlusCircleFill />&nbsp;&nbsp;$ 1254.00
                    </Button>
                  </Grid>
                  <Grid item md={3}>
                    <Box style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-around"
                    }} >
                      <IconButton variant="contained" color="primary" >
                        <BsBellFill className="notification" onClick={handleClickOpen1} />
                      </IconButton>
                      <Avatar alt="Remy Sharp" src="/images/user.png" 
                      onClick = {handleClickOpen}
                        style={{ cursor: "pointer" }} />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>          </Grid>
        </Toolbar>
        {
          openUserProfile && (
            <Dialog
              fullWidth
              maxWidth="xs"
              open={openUserProfile}
              classes={{ paper: classes.dialogPaper }}
              keepMounted
              onClose={handleClose}
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
            >
              <UserProfileComponent />
            </Dialog>
          )
        }

        <Dialog
          fullWidth
          maxWidth="xs"
          open={openUsenotification}
          keepMounted
          onClose={handleClose1}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <NotificationModal />
        </Dialog>

        {/* <ModalContext.Provider value={{ showModal, toggleModal }}>
          <div>
            <BetBuilderModal canShow={showModal} updateModalState={toggleModal}>
              <BetBuilder />
            </BetBuilderModal>
          </div>
        </ModalContext.Provider> */}
      </Container>
    );
  };

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));

    return (
      <Toolbar className={mainHeader}>
        <Drawer
          {...{
            anchor: "right",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <div className={drawerContainer}>
            <img className={logoDrawer} src="images/logotype02.png" alt="" />
            <Box style={{ width: "100%", padding: "0px 10px" }}>
              <GloballySearchArea type="TopHeaderSearchArea" />
            </Box>
            <UserProfileComponent />
            <Box className={menuSocial} px={2}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Button variant="contained" color="primary" fullWidth onClick={() => history.push("/LeaderBoard")} >
                    Leaderboard
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button variant="contained" color="primary" fullWidth onClick={() => history.push("/bet-builder")}>
                    Bet Builder
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h6" className={houseCut}>HOUSE CUT : 3%</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Button variant="contained" color="primary" fullWidth onClick={() => history.push("/wallet")} className="buttontopBox">
                    <BsFillPlusCircleFill />&nbsp;&nbsp;Deposit
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Select
                    className="selectbox3"
                    variant="outlined"
                    size="small"
                    fullWidth
                    name="weather"
                    value={select1}
                    onChange={handleChange1}
                  >
                    <MenuItem value={"BTC"}>
                      <Box className="selectBox" style={{ display: "flex", alignItems: "center" }}>
                        <img src="images/btc.png" alt="image" style={{ width: "22px" }} />
                        <Typography variant="h5" style={{
                          fontSize: "14px",
                          marginLeft: "7px",
                        }}>BTC</Typography>
                      </Box>
                    </MenuItem>
                    <MenuItem value={"BNB"}>
                      <Box className="selectBox" style={{ display: "flex", alignItems: "center" }}>
                        <img src="images/bnb.png" alt="image" style={{ width: "22px" }} />
                        <Typography variant="h5" style={{
                          fontSize: "14px",
                          marginLeft: "7px",
                        }}>BTC</Typography>
                      </Box>
                    </MenuItem>
                    <MenuItem value={"ETH"}>
                      <Box className="selectBox" style={{ display: "flex", alignItems: "center" }}>
                        <img src="images/eth.png" alt="image" style={{ width: "22px" }} />
                        <Typography variant="h5" style={{
                          fontSize: "14px",
                          marginLeft: "7px",
                        }}>BTC</Typography>
                      </Box>
                    </MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={6}>
                  <Button variant="contained" color="primary" fullWidth >
                    < FaDollarSign /> 1254.00
                  </Button>
                </Grid>
              </Grid>
            </Box>
            {/* {menuLinks} */}
          </div>
        </Drawer>
        <Hidden mdDown implementation="css">
          <Drawer
            variant="temporary"
            anchor="left"
            open={mobileOpen}
            onClose={handleDrawerToggle}


            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <IconButton onClick={handleDrawerToggle} style={{ justifyContent: "end" }}>
              <CloseIcon style={{ fontSize: "27px" }} />
            </IconButton>
            <Box className={drawerPaper}>
              <NavLink />
            </Box>
          </Drawer>
        </Hidden>
        <Box className="iconLeftSide" >
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            edge="start"
            onClick={handleDrawerToggle}
          // className={classes.menuButton}
          >
            <HiMenuAlt2 style={{ fontSize: "30px" }} />
          </IconButton>
        </Box>

        <div>{productLogo}</div>

        <Grid container>
          <Grid item xs={10}></Grid>
          <Grid item xs={2}>
            <Box className="iconRightSide" pr={2} >
              <IconButton
                className={drawericon}
                {...{
                  edge: "start",
                  color: "inherit",
                  "aria-label": "menu",
                  "aria-haspopup": "true",
                  onClick: handleDrawerOpen,
                }}
              >
                <MenuIcon
                  width="60px"
                  height="60px"
                  style={{ fontSize: "30px" }}
                />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </Toolbar>
    );
  };

  const productLogo = (
    <Box pt={1} pl={1}>
      <Link to="/">
        <Logo className="logoImg" alt="image" />
      </Link>
    </Box>
  );

  return (

    <>
      <AppBar
        className={Topbar}
        elevation={0}
      >
        <Container maxWidth="fixed" className={containerHeight}>
          {mobileView ? displayMobile() : displayDesktop()}
        </Container>
      </AppBar>
      <Box className="themeButton">
        <IconButton
          variant="contained"
          color="primary"
          onClick={() => {
            changeTheme("LIGHT");
          }}
        >
          <FiSun />
        </IconButton>
        <IconButton
          variant="contained"
          color="primary"
          onClick={() => {
            changeTheme("DARK");
          }}
        >
          <FaRegMoon />
        </IconButton>
      </Box>
    </>
  );
}
