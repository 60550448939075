import React, { useState } from "react";
import { makeStyles, Button, Box, Grid } from "@material-ui/core";
import ActiveBetsCard from "src/component/ActiveBetsCard";
import SettleBetsCard from "src/component/SettleBetsCard";
import { DataList } from "src/Constant/Index";

const useStyles = makeStyles((theme) => ({
  betBox: {
    border: "0.5px solid #7784DA",
    borderRadius: "10px",
    overflow: "hidden",
    marginTop: "15px",
  },
  dailogHeader: {
    padding: "15px",
    "& .mainContent": {
      "& button": {
        marginRight: "10px",
        "&.hover": {
          color: theme.palette.text.white,

        },
        "&.active": {
          color: theme.palette.text.primary,
          border: "1px solid #70D5FB"
        },
      },
    }
  },
  sideDialogHeader: {
    padding: "20px 0px",
    "& .mainContent": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& button": {
        marginRight: "10px",
        minWidth: "55px",
        borderRadius: "10px",
        height: "30px",
        "&.active": {
          color: theme.palette.text.primary,
          border: "1px solid #70D5FB"
        },
      },
    }
  },
}));

export default function (props) {
  const classes = useStyles();
  const { type } = props;
  const [tabview, setTabView] = useState("activeBets");
  return (
    <>
      <Box className={type === "Sidebet" ? `${classes.sideDialogHeader}` : `${classes.dailogHeader}`} >
        <Box className="mainContent" textAlign="left">
          <Button
            variant="contained"
            size="large"
            color="primary"
            className={tabview === "activeBets" ? "active" : ""}
            onClick={() => setTabView("activeBets")}
          >
            {
              type === "Sidebet" ? "Active" : " ACTIVE BETS"
            }
          </Button>
          <Button
            variant="contained"
            size="large"
            color="primary"
            className={tabview === "settleBets" ? "active" : ""}
            onClick={() => setTabView("settleBets")}
          >
            {
              type === "Sidebet" ? "Settle" : "SETTLED BETS"
            }
          </Button>
        </Box>
        <Box mt={3}>
          {tabview === "activeBets" ?
            <Grid container spacing={2}>
              {
                DataList && DataList?.map((data, index) => {
                  return (<> {
                    type === "Sidebet" ? <Grid item xs={12}>
                      <ActiveBetsCard data={data} key={index} />
                    </Grid> : <Grid item xs={12} sm={6} md={6} lg={3}>
                      <ActiveBetsCard data={data} key={index} />
                    </Grid>
                  }
                  </>
                  )
                })
              }
            </Grid> : " "}
          {tabview === "settleBets" ? <Grid container spacing={2}>
            {
              DataList && DataList?.map((data, index) => {
                return (<>
                  {
                    type === "Sidebet" ? <Grid item xs={12}>
                      <SettleBetsCard data={data} key={index} />
                    </Grid> : <Grid item xs={12} sm={6} md={6} lg={3}>
                      <SettleBetsCard data={data} key={index} />
                    </Grid>
                  }
                </>
                )
              })
            }
          </Grid> : " "}
        </Box>
      </Box>
    </>
  );
}
