import React from 'react';
import { makeStyles, Box, Typography, Grid, Button, Divider,withStyles } from '@material-ui/core';
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const useStyles = makeStyles((theme) => ({
    navAccordionicon: {
        background: theme.palette.background.navAccordionicon,
        borderRadius: "4px",
        width: "30px"
    },
    detailsBox: {
        width: "100%",
        display: "flex",
        padding: "15px",
        borderRadius: "4px",
        alignItems: "center",
        justifyContent: "space-between",
        "& p": {
            color: theme.palette.text.cossafaText,
        }
    },
    buttonStyle: {
        width: "60px",
        height: "35px",
        fontSize: "16px",
    },
}));


const Accordion = withStyles((theme) => ({
    root: {
        boxSizing: "border-box",
        borderRadius: "20px !important",
        background: theme.palette.background.heading,
        "&:before": {
            display: "none",
        },
        "&$expanded": {
            border: " 1px solid #3d3d3d",
            background:
                "linear-gradient( 152.97deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%)",
            backdropFilter: "blur(42px)",
        },
    },
}))(MuiAccordion);

const AccordionSummary = withStyles((theme) => ({
    root: {
        boxSizing: "border-box",
        borderRadius: "20px !important",
        background: theme.palette.background.heading,
        "&$expanded": {
            minHeight: 50,
            borderBottom: "0",
            color: "#FFF",
            border: " none !important",
            background: theme.palette.background.heading,
            borderRadius: "20px 20px 0px 0px",
        },
        "@media(max-width:605px)": {
            fontSize: "10px",
            "&$expanded": {
                borderBottom: "0",
                color: "#FFF",
                backgroundColor: "transparent",
                border: " none !important",
            },
        },
    },
    content: {
        background: "000 !important",
        color: "#fff",
        borderRadius: "5px",
        "&$expanded": {
            margin: "0px 0",
            border: " none !important",
        },
    },
    expanded: {
        margin: "0",
    },
}))(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        background: theme.palette.background.default,
        borderRadius: " 0px 0px 20px 20px !important",
        padding: "1px 0px 8px",
        "& svg": {
            color: "red",
        },
    },
}))(MuiAccordionDetails);




function DummySearchContent(props) {
    const classes = useStyles();
    const {playersPropsData} = props;
    const [expanded, setExpanded] = React.useState("panel1");

    return (
        <>
            <Box className={classes.root}>
                <Accordion
                    square
                    defaultExpanded={true}
                >
                    <AccordionSummary
                        id="panel1a-header"
                        expandIcon={
                            <Box className={classes.navAccordionicon} >
                                {expanded === true ? (
                                    <KeyboardArrowUpIcon />
                                ) : (
                                    <KeyboardArrowDownIcon />
                                )}
                            </Box>
                        }
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Grid container spacing={2}>
                                    <Grid item xs={8}>
                                        <Typography
                                            variant="h6"
                                            style={{
                                                fontSize: "15px"
                                            }}
                                        >
                                            {playersPropsData?.heading}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Box className='d-flexspacebetween'>
                                            <Typography
                                                variant="h6"
                                                style={{
                                                    fontSize: "15px",
                                                    color: "#787878"
                                                }}
                                            >
                                                YES
                                            </Typography>

                                            <Typography
                                                variant="h6"
                                                style={{
                                                    fontSize: "15px",
                                                    marginRight: "20px",
                                                    color: "#787878"
                                                }}
                                            >
                                                NO
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container spacing={2}>
                                    <Grid item xs={8}>

                                    </Grid>
                                    <Grid item xs={4}>
                                        <Box className='d-flexspacebetween'>
                                            <Typography
                                                variant="h6"
                                                style={{
                                                    fontSize: "15px",
                                                    marginLeft: "20px",
                                                    color: "#787878"
                                                }}
                                            >
                                                YES
                                            </Typography>

                                            <Typography
                                                variant="h6"
                                                style={{
                                                    fontSize: "15px", marginRight: "10px",
                                                    color: "#787878"
                                                }}
                                            >
                                                NO
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails style={{ display: "flex", flexDirection: "column" }}>
                        <Box my={1}>
                            <Grid container spacing={2}>
                                {
                                    playersPropsData?.description && playersPropsData?.description?.map((data, index) => {
                                        return (
                                            <Grid item xs={12}>
                                                <Box className='dsplay-flex-center'>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} md={6}>
                                                            <Box className={classes.detailsBox}>
                                                                <Grid container spacing={2}>
                                                                    <Grid item xs={7}>
                                                                        <Typography variant='body2'>{data?.text1}</Typography>
                                                                    </Grid>
                                                                    <Grid item xs={5}>
                                                                        <Box style={{
                                                                            display: " flex",
                                                                            alignItems: "center",
                                                                            justifyContent: "flex-end"
                                                                        }}>
                                                                            <Button variant='outlined' color="primary" className={classes.buttonStyle}>
                                                                                {data?.num1}
                                                                            </Button>&nbsp;&nbsp;
                                                                            <Button variant='outlined' color="secondary" className={classes.buttonStyle}>
                                                                                {data?.num1}
                                                                            </Button>
                                                                        </Box>
                                                                    </Grid>
                                                                </Grid>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            <Divider orientation="vertical" flexItem />
                                                            <Box className={classes.detailsBox}>
                                                                <Grid container spacing={2}>
                                                                    <Grid item xs={7}>
                                                                        <Typography variant='body2'>{data?.text2}</Typography>
                                                                    </Grid>
                                                                    <Grid item xs={5}>
                                                                        <Button variant='outlined' color="primary" className={classes.buttonStyle}>
                                                                            {data?.num1}
                                                                        </Button>&nbsp;&nbsp;
                                                                        <Button variant='outlined' color="secondary" className={classes.buttonStyle}>
                                                                            {data?.num1}
                                                                        </Button>
                                                                    </Grid>
                                                                </Grid>
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                        )
                                    })
                                }
                            </Grid>
                        </Box>
                    </AccordionDetails>
                </Accordion >
            </Box>
        </>
    )
}

export default DummySearchContent;