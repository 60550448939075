import { NavLink } from "react-router-dom";
import React from "react";
import { makeStyles, Button, MenuItem, Avatar, } from "@material-ui/core";
import { IoBasketballOutline } from "react-icons/io5";
import { FaUserAlt } from 'react-icons/fa';
import { FaWallet } from 'react-icons/fa';
import { MdStarRate } from 'react-icons/md';
import { IoMdNotificationsOutline } from 'react-icons/io';
import { HiUsers } from 'react-icons/hi';
import { FaQuestionCircle } from 'react-icons/fa';
import { BiSupport } from 'react-icons/bi';
import { GiTwoCoins } from 'react-icons/gi';
import { BiTransfer } from 'react-icons/bi';
import { MdLeaderboard } from 'react-icons/md';
import { AiFillHome } from "react-icons/ai";



const useStyles = makeStyles((theme) => ({

  MenuItem: {
    color: "#787878",
    // padding: "10px 30px",
    fontSize: "14px",
    fontWeight: "600",
    textTransform: "uppercase",
    "&.active": {
      background:
        "radial-gradient(98.18% 613.64% at 78.18% 50%, rgba(51, 153, 170, 0.81) 0%, rgba(51, 153, 170, 0) 100%) , radial-gradient(60% 120.26% at 40% 60%, #6437C0 0%, #6437C0 100%) , linear-gradient(159.8deg, #7784DA 24.32%, #7784DA 53.17%)",
    },
    "& svg": {
      fontSize: "15px",
      color: "#787878",

    },
    "& .menuText": {
      paddingLeft: "14px",
      fontSize: "14px !important",
    },
    "& .MuiAvatar-root": {
      width: "30px",
      height: "30px",

    }
  },

}));
const headersData = [
  {
    label: 'Home',
    href: '/',
    icon: <AiFillHome />,
  },
  {
    label: 'My Account',
    href: '/my-account',
    icon: <FaUserAlt />,
  },
  {
    label: 'Wallet',
    href: '/wallet',
    icon: <FaWallet />,
  },
  {
    label: 'Favourites',
    href: '/favourites',
    icon: <MdStarRate />,
  },
  {
    label: 'Notification',
    href: '/notifications',
    icon: <IoMdNotificationsOutline />,
  },
  {
    label: 'Transactions',
    href: '/transaction',
    icon: <BiTransfer />,
  },
  {
    label: 'My Bets',
    href: '/my-bets',
    icon: <GiTwoCoins />,
  },
  {
    label: 'Referral',
    href: '/referrals',
    icon: <HiUsers />,
  },
  {
    label: 'Leaderboard',
    href: '/leaderBoard',
    icon: <MdLeaderboard />,
  },
  {
    label: 'Rules',
    href: '/rules',
    icon: <IoBasketballOutline />,
  },
  {
    label: 'FAQ',
    href: '/faq',
    icon: <FaQuestionCircle />,
  },
  {
    label: 'Support',
    href: '/support',
    icon: <BiSupport />,
  },
]
export default function (props) {
  const classes = useStyles();

  return headersData.map(({ label, href, icon }) => {
    return (
      <>
        <NavLink
          activeClassName="active" exact
          {...{
            key: label,
            color: "inherit",
            to: href,
          }}
        >
          <MenuItem className={`${classes.MenuItem} menuitem`}>
            <Avatar className={classes.AvatarIcon}>{icon}</Avatar><span className="menuText">{label}</span></MenuItem>
        </NavLink>
      </>
    )
  })
}
