import { Box } from "@material-ui/core";
import React, { useState } from "react";
import {
  makeStyles,
  Button,
  Typography,
  Grid,
  Slide,
} from "@material-ui/core";
import BetBox from "src/component/BetBox";
import { useHistory } from "react-router-dom";
import ParlaySuggestion from "src/component/ParlaySuggestion";
import MyBets from "src/views/pages/MyBets/MyBets";
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  UserBox: {
    "& h5": {
      color: theme.palette.text.noticetext,
    },
  },
  acitiveBox: {

    "& button": {
      minWidth: "auto",
    },
    "& button": {
      minWidth: "auto",
      borderRadius: "5px",
      padding: "6px 27px",
      background: theme.palette.button.primary,
      "&.active": {
        color: "#444343",
        background: "linear-gradient(105.19deg, #8DE6D1 2.66%, #2FB3FC 81.96%), linear-gradient(105.19deg, #8DE6D1 2.66%, #2FB3FC 81.96%)",
        boxShadow: "-10px -10px 20px rgba(255, 255, 255, 0.25), 10px 10px 20px rgba(0, 0, 0, 0.25)",
      },
    },
  },

  acitiveBox1: {
    "& button": {
      minWidth: "auto",
      borderRadius: "10px",
      padding: "6px 27px",
      "&.active": {
        border: "1px solid #3399AA",
        borderRadius: "10px",
      },
    },
  },
  ButtonBox: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    // "& .MuiButton-contained": {
    //   "&:hover": {
    //     background: theme.palette.background.default,
    //     color:theme.palette.text.white,
    //   },
    // },
    "& button": {
      borderRadius: "50px",
      minWidth: "auto",
      height: "40px",
      fontWeight: "800",
      color: theme.palette.text.white,
      background: theme.palette.background.default,
      border: "none !important",
      boxShadow: "-6px -7px 10px rgb(255 255 255 / 5%), 2px 2px 20px rgb(0 0 0 / 56%)",
      fontSize: "14px",
      whiteSpace: "pre",
      //   // "&:hover": {
      //   //   background: "none !important",
      //   // },
      // },
      // "&.hover": {
      //   color: "#444343",
      //   background: "linear-gradient(105.19deg, #8DE6D1 2.66%, #2FB3FC 81.96%), linear-gradient(105.19deg, #8DE6D1 2.66%, #2FB3FC 81.96%)",
      // },
      "&.active": {
        color: "#444343",
        background: "linear-gradient(105.19deg, #8DE6D1 2.66%, #2FB3FC 81.96%), linear-gradient(105.19deg, #8DE6D1 2.66%, #2FB3FC 81.96%)",
      },
    },
  },
  greenButton: {
    background: "linear-gradient(105.19deg, #8DE6D1 34.46%, #2FB3FC 81.96%), linear-gradient(120.84deg, rgba(0, 240, 255, 0.77) 12.49%, #00FFAB 49.56%, #00FFAB 86.85%)",
    boxShadow: "0px 0px 10px #8de6d1, -10px -10px 20px rgb(255 255 255 / 0%), 10px 10px 20px rgb(0 0 0 / 50%)",
    color: "#444343",
    fontSize: "17px",
    fontWeight: 700,

  },
  fullscreenModal: {
    "& .MuiDialog-paperFullScreen": {
      top: "26px",
      height: "calc(100% - 80px)",
      background: theme.palette.background.paper,
    },
  },
  appBar: {
    position: "relative",
    borderRadius: "43px",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },



}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function (props) {
  const classes = useStyles();
  const history = useHistory();
  const { type, closeMyBetsHandler } = props;
  const [tabviewsideBet, setTabViewsideBet] = useState("betSlip");


  return (
    <Box className={classes.UserBox}>
      <Box py={1}>
        {type === "dialogcontent" && (<CloseIcon onClick={closeMyBetsHandler} style={{
          position: "absolute",
          top: "5px",
          right: "5px", cursor: "pointer"
        }} />)}
      </Box>
      <Box className={classes.ButtonBox}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button fullWidth className={tabviewsideBet === "betSlip" ? "active" : ""}
              onClick={() => setTabViewsideBet("betSlip")}>BET SLIP</Button>
          </Grid>
          <Grid item xs={6}>
            <Button fullWidth className={tabviewsideBet === "Mybets" ? "active" : ""}
              onClick={() => setTabViewsideBet("Mybets")} >MY BETS</Button>
          </Grid>
        </Grid>
      </Box>
      {tabviewsideBet === "betSlip" ? (
        <>
          <Box mt={2}>
            <Typography variant="body2" color="primary">Your Bets</Typography>
            <BetBox />
            <BetBox />
          </Box>
          <Box mt={2}>
            <Box className="justifyBetween" mb={1}>
              <Typography variant="body2" color="primary" className="textBlue">Total Odds</Typography>
              <Typography variant="h5" color="noticetext" style={{ fontSize: "15px" }}>2</Typography>
            </Box>

            <Box className="justifyBetween" mb={1}>
              <Typography variant="body2" color="primary" className="textBlue">Total Stake</Typography>
              <Typography variant="h5" color="white" style={{ fontSize: "15px" }}>0.000100</Typography>
            </Box>
            <Box className="justifyBetween" mb={1}>
              <Typography variant="body2" color="primary" className="textBlue">Total Payout</Typography>
              <Typography variant="h5" color="white" style={{ fontSize: "15px" }}>0.000100</Typography>
            </Box>
          </Box>
          <Box mt={2} align="center">
            <Button variant="contained" size="large" fullWidth className={classes.greenButton}>Bet Now</Button>
          </Box>
          <Box mt={2}>
            <ParlaySuggestion />
          </Box></>
      ) : (
        ""
      )}

      {tabviewsideBet === "Mybets" ? (
        <>
          <MyBets type="Sidebet" />
          <Box mt={2}>
            <Button variant="contained" size="small" fullWidth className={classes.greenButton} onClick={() => history.push("/my-bets")}>View More</Button>
          </Box>
        </>
      ) : (
        ""
      )}


    </Box>
  );
}
