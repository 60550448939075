import React, { useState } from 'react';
import { makeStyles, TextField, Box, IconButton, InputAdornment, Dialog, Grid, Button } from '@material-ui/core';
import { BsSearch } from "react-icons/bs";
import CloseIcon from '@material-ui/icons/Close';
import DummySearchContent from './DummySearchContent';

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        borderRadius: "20px",
        backgroundColor: theme.palette.background.default,
        "& .searchBox": {
            "& .MuiOutlinedInput-root": {
                borderRadius: "100px",
                "& .MuiOutlinedInput-input": {
                    height: "28px",
                },
            },
        },
    },
    mainBox: {
        "& .heading": {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
        }
    }
}));

const cardData = [
    {
        heading: "ALL STATISTICS",
        description: [
            {
                text1: "Luka Doncic to have 7+ assists",
                num1: "+300",
                num2: "+150",
                text2: "Lebron James to have less than 5 assists",
            }
        ]
    },
    {
        heading: "ASSISTS",
        description: [
            {
                text1: "Luka Doncic to have 7+ assists",
                num1: "+300",
                num2: "+150",
                text2: "Lebron James to have less than 5 assists",
            }
        ]
    },
    {
        heading: "ASSISTS",
        description: [
            {
                text1: "Luka Doncic to have 7+ assists",
                num1: "+300",
                num2: "+150",
                text2: "Lebron James to have less than 5 assists",
            }
        ]
    },
    {
        heading: "ASSISTS",
        description: [
            {
                text1: "Luka Doncic to have 7+ assists",
                num1: "+300",
                num2: "+150",
                text2: "Lebron James to have less than 5 assists",
            }
        ]
    },
    {
        heading: "ASSISTS",
        description: [
            {
                text1: "Luka Doncic to have 7+ assists",
                num1: "+300",
                num2: "+150",
                text2: "Lebron James to have less than 5 assists",
            }
        ]
    },
]



function GloballySearchArea(props) {
    const classes = useStyles();
    const { type } = props;
    const [dialogOpen, setDialogOpen] = useState(false)
    const handleClose = () => {
        setDialogOpen(false);
    };
    return (
        <>
            <Box className={classes.root}>
                {/* //Only for Headers Topbar  */}
                {
                    type === "TopHeaderSearchArea" ? (
                        <TextField
                            id="outlined-basic"
                            type="text"
                            value="Search"
                            variant="outlined"
                            fullWidth
                            placeholder="Search"
                            className="searchBox"
                            onClick={() => setDialogOpen(true)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        {" "}
                                        <IconButton
                                            style={{ fontSize: "20px", padding: "0px" }}
                                            className="iconbtn"
                                        >
                                            <BsSearch style={{ color: "#8d8d8f", fontSize: "16px" }} />
                                        </IconButton>{" "}
                                    </InputAdornment>
                                ),
                            }}
                        />
                    ) : (
                        <Box>
                            {/* //For Globally Sear Area */}
                            <TextField
                                id="outlined-basic"
                                type="search"
                                variant="outlined"
                                fullWidth
                                placeholder="Search"
                                className="searchBox"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            {" "}
                                            <IconButton
                                                style={{ fontSize: "20px", padding: "0px" }}
                                                className="iconbtn"
                                            >
                                                <BsSearch style={{ color: "#8d8d8f", fontSize: "16px" }} />
                                            </IconButton>{" "}
                                        </InputAdornment>
                                    ),
                                }}
                            />

                        </Box>
                    )
                }
            </Box>
            <Dialog
                PaperProps={{
                    style: {
                        backgroundColor: "transparent",
                        boxShadow: "none"
                    },
                }}
                maxWidth="lg"
                open={dialogOpen} onClose={() => setDialogOpen(false)}>
                <Box className={classes.mainBox}>
                    <Box className='heading'>
                        <span> Search....</span>
                        <Button className={classes.icons} onClick={handleClose}>
                            <CloseIcon />
                            Close
                        </Button>
                    </Box>
                    <Box className={classes.root} my={3}>
                        <TextField
                            id="outlined-basic"
                            type="search"
                            // value="Search".
                            variant="outlined"
                            fullWidth
                            placeholder="Search"
                            className="searchBox"
                            style={{ backgroundColor: "222222 !important" }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        {" "}
                                        <IconButton
                                            style={{ fontSize: "20px", padding: "0px" }}
                                            className="iconbtn"
                                        >
                                            <BsSearch style={{ color: "#8d8d8f", fontSize: "16px" }} />
                                        </IconButton>{" "}
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                </Box>
                <Grid container spacing={2} direction={"column"}>
                    {
                        cardData && cardData?.map((playersPropsData, index) => {
                            return (
                                <Grid item xs={12}>
                                    <DummySearchContent playersPropsData={playersPropsData} type="playersProps" key={index} />
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </Dialog>
        </>
    )
}

export default GloballySearchArea;